<template>
  <layout-admin-dashboard-view
    :show-toolbar="false"
    :back-route="{ name: 'entity-dashboard' }">
    <template #title>
      <span
        >{{ translateTitleCase(`entity.crud.listTitleGeneric`) }} -
        {{ translateTitleCase(`entity.${entityType}.namePlural`) }}</span
      >
    </template>

    <template #default>
      <crud-list
        :create-route="{ name: 'entity-create', params: { entityType } }"
        :edit-route="getEditRoute"
        :api-delete-url="'entity/' + entityType"
        :api-get-url="'entity/' + entityType"
        :api-order-url="'entity/' + entityType + '/setOrdering'"
        :api-update-url="getUpdateApiUrl"
        :show-filters="showingFilters"></crud-list>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import { useStore } from "vuex";

import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
//    import paginationComposition  from '@/client/extensions/composition/pagination.js';
import CrudList from "@/client/components/crud/List.vue";
import { computed, reactive, getCurrentInstance } from "vue";

export default {
  components: { CrudList },
  props: {
    entityType: {
      type: String,
      default: "default",
    },
  },
  setup(props, setupContext) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props, useStore());

    return { asyncOps, asyncOpsReady, asyncStatus };
  },
  data: function () {
    return {
      showingFilters: false,
    };
  },
  computed: {},
  methods: {
    getEditRoute(id) {
      return {
        name: "entity-edit",
        params: { entityType: this.entityType, entityId: id },
      };
    },
    getUpdateApiUrl(arg) {
      return "entity/" + this.entityType + "/" + arg;
    },
    getDeleteApiUrl(payload) {
      // arg can be changed, and it will change the payload sent on the delete request
      return "entity/" + this.entityType;
    },

    getItemRouteConfig(id) {
      return {
        name: "entity-edit",
        params: { entityType: this.entityType, entityId: id },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.meta-column-header {
  white-space: nowrap;
}

.list-column-header {
  position: relative;
  cursor: pointer;

  .col-sort-indicator {
    position: absolute;
    top: calc(50% + 1px);
    left: -5px;
    transform: translateY(-50%);
    z-index: 5;
    display: inline-block;
  }
}
</style>
